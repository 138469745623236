@import '~bootstrap-icons/font/bootstrap-icons.scss';
@import '~bootstrap/scss/bootstrap.scss';


.home-section {
    background-image: url(https://bharatstore.blob.core.windows.net/public-content/web/bellamkonda/contents/httpsbellamkonda_me_bg.avif);
    background-size: cover;
    min-height: 400px;
    padding-top: 70px;

    .text-content{
        background-color: grey;        
        padding-left: 15px;
        padding-top: 20px;
        padding-bottom: 20px;
    }
}

@media (max-width: 1460px) {
    .home-section {        
        padding-top: 220px;
    }
}
